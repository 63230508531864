import axios from 'axios'
import urlConfig from '@/api/urlConfig'

export default class UserApi {
  static async updateProgress (ldap, store, productId) {
    const config = {
      method: 'put',
      url: `${urlConfig.updateProgress}`,
      data: { ldap, store, productId },
      withCredentials: true
    }
    const response = await axios(config)
    return response.data
  }

  static async submitWin (ldap, store) {
    const config = {
      method: 'put',
      url: `${urlConfig.submitWin}`,
      data: { ldap, store },
      withCredentials: true
    }
    const response = await axios(config)
    return response.data
  }
}
