import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Progress from '../views/Progress.vue'
import Map from '@/views/Map'
import Scan from '@/views/Scan'
import Product from '@/views/Product'
import Leaderboard from '@/views/Leaderboard'
import routeNames from './routeNames'
import InstructionsPage from '@/views/InstructionsPage'

Vue.use(VueRouter)

const routes = [
  {
    path: routeNames.home,
    name: 'Home',
    component: Home
  },
  {
    path: routeNames.instructions,
    name: 'InstructionsPage',
    component: InstructionsPage,
    meta: { transitionName: 'slide' }
  },
  {
    path: routeNames.progress,
    name: 'Progress',
    component: Progress,
    meta: { transitionName: 'slide' }
  },
  {
    path: routeNames.map,
    name: 'Map',
    component: Map,
    meta: { transitionName: 'slide' }
  },
  {
    path: routeNames.scan,
    name: 'Scan',
    component: Scan,
    meta: { transitionName: 'slide' }
  },
  {
    path: routeNames.product,
    name: 'Product',
    component: Product,
    meta: { transitionName: 'slide' }
  },
  {
    path: routeNames.leaderboard,
    name: 'Leaderboard',
    component: Leaderboard,
    meta: { transitionName: 'slide' }
  },
  {
    path: routeNames.admin,
    name: 'Admin',
    component: () => import('../views/admin/Admin.vue'),
    meta: { transitionName: 'slide' }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
