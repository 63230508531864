<template>
  <div class="fill-height">
    <BlackRotateScreen />
    <v-col class="map fill-height">
      <v-container class="d-flex align-center pa-0" fluid>
        <v-img :src="map" contain width="0">
          <MarkerItem
            v-for="(item, i) in markerCoordinates"
            :key="i"
            :id="i"
            :left="item.left"
            :top="item.top"
            :src="marker"
            @click="markerClickHandler"
          />
        </v-img>
      </v-container>
      <v-col class="d-flex flex-column justify-center align-end fill-height">
        <MapActionButton :icon="scan" text="Scan" @click="scanClickHandler" />
        <MapActionButton
          :icon="returnIcon"
          text="Return"
          @click="$router.back()"
        />
      </v-col>
      <v-dialog v-model="dialog" width="40%" content-class="rounded-lg">
        <v-card
          class="dialog d-flex flex-column align-center justify-center pt-7 pb-7"
        >
          <v-img :src="lamp" max-height="100" contain></v-img>
          <p class="dialog-text mt-5">{{ tipText }}</p>
          <v-btn
            color="#A53100"
            width="50%"
            height="60"
            class="dialog-button mt-3"
            @click="dialog = false"
          >
            <span>Got it!</span>
          </v-btn>
        </v-card>
      </v-dialog>
    </v-col>
  </div>
</template>

<script>
import MapActionButton from "@/components/ui/buttons/MapActionButton";
import scan from "../assets/images/icons/scan2.svg";
import returnIcon from "../assets/images/icons/return.svg";
import lamp from "../assets/images/icons/lamp.svg";
import map from "../assets/images/event-map.svg";
import marker from "../assets/images/icons/Marker.svg";
import MarkerItem from "@/components/MarkerItem";
import BlackRotateScreen from "@/components/BlackRotateScreen";
import tips from "@/data/productTips";
import markerCoordinates from "@/data/markerCoordinates";
import routeNames from "@/router/routeNames";

export default {
  name: "Map",
  components: { BlackRotateScreen, MarkerItem, MapActionButton },
  data() {
    return {
      map,
      marker,
      scan,
      lamp,
      returnIcon,
      dialog: false,
      tipText: "",
      markerCoordinates,
    };
  },
  methods: {
    markerClickHandler(id) {
      this.tipText = tips.en[id];
      this.dialog = true;
    },
    scanClickHandler() {
      this.$router.push(routeNames.scan);
    },
  },
};
</script>

<style scoped>
.map {
  display: none !important;
  background: white;
}

.dialog {
  display: none !important;
}

.dialog-text {
  width: 75%;
  font-family: "Acumin Pro Bold", Roboto, sans-serif;
  text-align: center;
}

.dialog-button {
  border-radius: 10px;
  color: white;
}

@media screen and (orientation: landscape) {
  .map {
    display: flex !important;
  }

  .dialog {
    display: flex !important;
  }
}
</style>
