export default [
  {
    id: 1,
    name: "New hire",
    namefr: "",
    description: {
      title: "Description",
      text: "The Safety Passport is one of our main programs to ensure that new hires have all the information they need regarding maintaining a safe working and shopping environment in our stores.",
      textFr: "",
    },
    course: {
      title: "Course of Action",
      titleFr: "",
      text: "Always keep a Safety Passport in your apron to remember all the key topics that keep you safe every day!",
      textFr: "",
    },
  },
  {
    id: 2,
    name: "Two Person Lift",
    namefr: "",
    description: {
      title: "Description",
      text: "Any lift can lead to an injury if it’s not done properly, and even if the item is not too heavy.",
      textFr: "",
    },
    course: {
      title: "Course of Action",
      titleFr: "",
      text: 'To prevent overexertion injuries, team up with a fellow associate if the item is too heavy or awkward, if you are doing repetitive lifting, or if the product is labeled with a "Two Person Lift" icon.',
      textFr: "",
    },
  },
  {
    id: 3,
    name: "Carts",
    namefr: "",
    description: {
      title: "Description",
      text: "Choosing the right cart based on the type of load helps prevent injuries involving associates and customers.",
      textFr: "",
    },
    course: {
      title: "Course of Action",
      titleFr: "",
      text: "Never hesitate to remind customers to use the correct cart: Shopping carts are for small articles, flat carts are for large and bulky items, and upright lumber carts are for lumber, building materials, and millwork.",
      textFr: "",
    },
  },
  {
    id: 4,
    name: "Safety Knife",
    namefr: "",
    description: {
      title: "Description",
      text: "Cuts and lacerations are very common types of injuries in our stores. These injuries can be the result of improper use of the safety knife or failure to use gloves..",
      textFr: "",
    },
    course: {
      title: "Course of Action",
      titleFr: "",
      text: "Do not alter your safety knife for any reason, the Home Depot issued knife is the only one approved for store use. Safety gloves are required when using the knife and when changing the blade.",
      textFr: "",
    },
  },
  {
    id: 5,
    name: "Zone of Safety (ZOS)",
    namefr: "",
    description: {
      title: "Description",
      text: "The moving Zone of Safety helps to ensure all associates and customers maintain a safe distance from moving lift equipment.",
      textFr: "",
    },
    course: {
      title: "Course of Action",
      titleFr: "",
      text: "Always maintain the ZOS, remember that it extends a minimum of 10 feet in the direction of travel and 4 feet on all other sides. Having a hard time picturing that distance? It’s like two sheet goods carts lined up end to end and in the direction of travel, and the 4 feet on a side it’s like the width of a standard sheet of drywall.",
      textFr: "",
    },
  },
  {
    id: 6,
    name: "Staying Hydrated",
    namefr: "",
    description: {
      title: "Description",
      text: "Extreme heat can lead to heat exhaustion, heat stroke, and other types of heat illness.",
      textFr: "",
    },
    course: {
      title: "Course of Action",
      titleFr: "",
      text: "When working inside or outside of the store: watch the temperature so you are prepared, drink plenty of fluids, don’t wait until you’re thirsty to drink water, avoid dark colors in your clothes, and team up with your fellow associates to rotate tasks.",
      textFr: "",
    },
  },
  {
    id: 7,
    name: "Ladders",
    namefr: "",
    description: {
      title: "Description",
      text: "Not following the Ladder Safety Standards can lead to serious injuries.",
      textFr: "",
    },
    course: {
      title: "Course of Action",
      titleFr: "",
      text: "Always keep three points of contact – one hand must be on the rail at all times and NEVER overreach.",
      textFr: "",
    },
  },
  {
    id: 8,
    name: "Chemical Spills",
    namefr: "",
    description: {
      title: "Description",
      text: "Chemical spills are risky because they can lead to various types of injuries like slips, falls, chemical reactions on the skin, etc.",
      textFr: "",
    },
    course: {
      title: "Course of Action",
      titleFr: "",
      text: "When a hazardous spill is identified, immediately isolate the spill to prevent exposure to customers, associates, and yourself. Also, contact a designated HHM associate to safely clean the spill.",
      textFr: "",
    },
  },
];
