// Map is 673x348
export default [
  { left: "1.87", top: "3.77" },
  { left: "16.97", top: "4.22" },
  { left: "26.66", top: "4.22" },
  { left: "25.57", top: "17.87" },
  { left: "87.26", top: "20.18" },
  { left: "40.02", top: "71.49" },
  { left: "55.97", top: "71.49" },
  { left: "78.02", top: "71.19" },
];
