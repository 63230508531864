<template>
  <div class="d-flex flex-column align-center justify-space-around fill-height">
    <p v-if="error" class="error-text">{{error}}</p>
    <div class="overlay top-overlay"></div>
    <div class="overlay bottom-overlay"></div>
    <QrcodeStream @init="onInit" @decode="onDecode" :camera="camera">
      <div v-if="validationSuccess" class="validation-success">
        Product found!
      </div>
      <div v-if="validationFailure" class="validation-failure">
        Invalid QR Code
      </div>
      <div v-if="validationPending" class="validation-pending">
        Validation in progress...
      </div>
    </QrcodeStream>
  </div>
</template>

<script>
import { QrcodeStream } from 'vue-qrcode-reader'
import defineScannerError from '@/utils/defineScannerError'
import routeNames from '@/router/routeNames'

export default {
  name: 'Scan',
  components: {
    QrcodeStream
  },
  data () {
    return {
      error: '',
      isValid: undefined,
      camera: 'auto',
      result: null
    }
  },
  computed: {
    validationPending () {
      return this.isValid === undefined && this.camera === 'off'
    },
    validationSuccess () {
      return this.isValid === true
    },
    validationFailure () {
      return this.isValid === false
    }
  },
  methods: {
    async onDecode (result) {
      let res = decodeURIComponent(result.replace(/\/\?redirect=/g, ''))
      try {
        const linkRegexp = /\/progress\/scan\/product\/[-_0-9a-zA-Z]*$/
        if (linkRegexp.test(res)) {
          this.isValid = true
          this.result = result
        } else {
          this.isValid = false
        }
      } catch (e) {
        this.isValid = false
      }
      await this.timeout(1500)
      this.turnCameraOn()
      this.resetValidationState()
      if (this.result) {
        await this.$router.push(routeNames.productBase + '/' + res.split('/').pop())
      }
    },
    resetValidationState () {
      this.isValid = undefined
    },
    turnCameraOn () {
      this.camera = 'auto'
    },
    turnCameraOff () {
      this.camera = 'off'
    },
    timeout (ms) {
      return new Promise(resolve => {
        setTimeout(resolve, ms)
      })
    },
    async onInit (promise) {
      try {
        await promise
      } catch (error) {
        console.log(error)
        this.error = defineScannerError(error)
      }
    }
  }
}
</script>

<style scoped>
.error-text {
  margin-top: 20vh;
  color: red;
  font-size: 24px;
  text-align: center;
}

.overlay {
  position: absolute;
  left: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.50);
  z-index: 1;
}
.top-overlay {
  top: 0;
  height: 15vh;
}
.bottom-overlay {
  bottom: 0;
  height: 30vh;
}

.validation-success,
.validation-failure,
.validation-pending {
  position: absolute;
  width: 100%;
  height: 100%;

  background-color: rgba(255, 255, 255, .8);
  text-align: center;
  font-weight: bold;
  font-size: 20px;
  padding: 10px;

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
}
.validation-success {
  color: darkorange;
}
.validation-failure {
  color: red;
}
</style>
