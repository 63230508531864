<template>
  <v-col class="d-flex flex-column fill-height">
    <v-container class="d-flex flex-column justify-start align-center">
      <h2 class="mt-5 mb-10">Leaderboard</h2>
      <!-- <LeaderboardTable :regions="regions" /> -->
    </v-container>
    <v-container
      class="d-flex flex-column justify-center align-center pt-5 pb-5"
      style="
        background: rgba(165, 49, 0, 0.32);
        border-radius: 10px;
        max-width: 640px;
        width: 100%;
      "
    >
      <!-- <IconedButton
        :icon="binoculars"
        text="Start/Continue<br>Your Quest"
        color="#231F20"
        @click="startQuestHandler"
      /> -->
      <!-- <SubmitButton text="Exit" @click="exitButtonHandler" /> -->

      <p class="mb-0">
        To view your store's results, please view the leaderboard at APOTG<br />or
        click
        <a href="https://thd-ap.com/startsafe/mysafety" style="color: #231f20"
          >https://thd-ap.com/startsafe/mysafety</a
        >
        then > STE Experience
      </p>
    </v-container>
  </v-col>
</template>

<script>
import IconedButton from "../components/ui/buttons/IconedButton";
import LeaderboardTable from "@/components/LeaderboardTable";
import binoculars from "../assets/images/icons/Binoculars.svg";
import routeNames from "@/router/routeNames";
import LeaderboardApi from "@/api/leaderboard.api";
import SubmitButton from "@/components/ui/buttons/SubmitButton";

export default {
  name: "Leaderboard",
  components: {
    SubmitButton,
    LeaderboardTable,
    IconedButton,
  },
  data() {
    return {
      binoculars,
      regions: [],
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
  },
  methods: {
    async startQuestHandler() {
      if (this.user.completed) await this.$router.push(routeNames.final);
      else await this.$router.push(routeNames.progress);
    },
    exitButtonHandler() {
      this.$router.push(routeNames.home);
    },
  },
  async created() {
    const response = await LeaderboardApi.getStatistic(this.user.store);
    console.log(response);
    if (!response.error) {
      this.regions = response.data;
    }
  },
};
</script>

<style scoped></style>
