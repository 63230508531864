import UserApi from '@/api/user.api'

export default {
  state: {
    user: {
      ldap: localStorage.getItem('ldap') ?? '',
      store: localStorage.getItem('store') ?? '',
      region: '',
      district: '',
      progress: [],
      completed: null
    }
  },
  getters: {
    user: state => state.user,
    ldap: state => state.user.ldap,
    store: state => state.user.store
  },
  mutations: {
    setUser (state, user) {
      if (user) {
        state.user = user
      } else {
        state.user = {
          ldap: localStorage.getItem('ldap') ?? '',
          store: localStorage.getItem('store') ?? '',
          region: '',
          district: '',
          progress: [],
          completed: null
        }
      }
    },
    setLdap (state, ldap) {
      state.user.ldap = ldap
      localStorage.setItem('ldap', ldap)
    },
    setStore (state, store) {
      state.user.store = store
      localStorage.setItem('store', store)
    }
  },
  actions: {
    setUser ({ commit }, user) {
      commit('setUser', user)
    },
    async updateUserProgress ({ state, dispatch }, productId) {
      const response = await UserApi.updateProgress(state.user.ldap, state.user.store, productId)
      if (!response.error) state.user.progress.push(productId)
      if (state.user.progress.length === 8) dispatch('submitWinUser')
    },
    async submitWinUser ({ state }) {
      console.log('submitWinUser')
      const response = await UserApi.submitWin(state.user.ldap, state.user.store)
      console.log(response)
      if (!response.error) state.user.completed = true
    }
  }
}
