<template>
  <v-expansion-panels accordion multiple tile flat v-model="openedRegions" class="panels">
    <v-expansion-panel v-for="(region, i) in regions" :key="region.name">
      <v-expansion-panel-header class="score-header">
        {{ region.name }}
        <template v-slot:actions>
          <v-img :src="openedRegions.includes(i) ? minus : plus" />
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-simple-table>
          <template #default>
            <tbody>
            <tr
              v-for="(district, index) in region.districts"
              :key="district.number"
            >
              <td class="table-item-number pl-7 pr-4">{{ index + 1 }}</td>
              <td class="table-item-name">District {{ district.number }}</td>
              <td class="table-item-progress pl-4">
                <div
                  class="graphic-score"
                  :style="{
                      width: computeWidth(district.percentage),
                      background: computeColor(district.percentage)
                    }">
                </div>
              </td>
              <td class="table-item-percentage pl-4 pr-4">{{ district.percentage }}</td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import plus from '@/assets/images/icons/plus.svg'
import minus from '@/assets/images/icons/minus.svg'
import getLeaderboardColor from '@/utils/getLeaderboardColor'
import AuthApi from '@/api/auth.api'

export default {
  name: 'LeaderboardTable',
  props: {
    regions: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      plus,
      minus,
      connection: null,
      openedRegions: []
    }
  },
  methods: {
    computeWidth (percentage) {
      const percentageWidth = percentage.split('%')[0] / 100
      return percentageWidth * 90 + 'px'
    },
    computeColor (percentage) {
      const percentageWidth = percentage.split('%')[0]
      return getLeaderboardColor(percentageWidth)
    }
  },
  watch: {
    async regions () {
      const response = await AuthApi.validateUser(this.$store.getters.ldap, this.$store.getters.store)
      if (response.message) {
        const index = this.regions.findIndex(region => region.templateName === response.data.region)
        this.openedRegions = [index]
      }
    }
  }
}
</script>

<style scoped>
.panels {
  width: 95%;
}

.score-header {
  background: rgba(229, 229, 229, 0.44);
  border-bottom: 2px solid white;
  color: #4F4E4E;
  font-size: 18px;
  font-weight: 900;
}

.v-expansion-panel-content >>> .v-expansion-panel-content__wrap {
  padding: 0;
}

td {
  padding: 0 !important;
  vertical-align: middle;
}

.table-item-number {
  width: 0;
  font-weight: 900;
  color: #4F4E4E;
}

.table-item-name {
  width: 40%;
  color: #4F4E4E;
}

.table-item-progress {
  width: 35%;
}

.graphic-score {
  height: 13px;
  min-width: 15px;
  border-radius: 9px;
}

.table-item-percentage {
  width: 10%;
  font-size: 18px !important;
  color: #847F7C;
}
</style>
