<template>
  <v-app>
    <v-main class="main">
      <transition
        :name="transitionName"
        mode="out-in"
      >
        <router-view/>
      </transition>
    </v-main>
  </v-app>
</template>

<script>
import AuthApi from '@/api/auth.api'

const DEFAULT_TRANSITION = 'fade'

export default {
  name: 'App',
  data () {
    return {
      transitionName: DEFAULT_TRANSITION
    }
  },
  async created () {
    // auth validation
    const path = this.$route.path
    if (path !== '/') {
      const response = await AuthApi.validateUser(this.$store.getters.ldap, this.$store.getters.store)
      if (response.message) {
        await this.$store.dispatch('setUser', response.data)
        if (this.$store.getters.user.completed) {
          await this.$router.push('/')
        }
      } else {
        await this.$router.push({
          path: '/',
          query: { redirect: path }
        })
      }
    }
    // transition init
    this.$router.beforeEach((to, from, next) => {
      let transitionName = to.meta.transitionName || from.meta.transitionName
      if (transitionName === 'slide') {
        const toDepth = to.path === '/' ? 1 : to.path.split('/').length
        const fromDepth = from.path === '/' ? 1 : from.path.split('/').length
        transitionName = toDepth < fromDepth ? 'slide-right' : 'slide-left'
      }
      this.transitionName = transitionName || DEFAULT_TRANSITION
      next()
    })
  }
}
</script>

<style>
@import "assets/vendor/reset.css";

h2 {
  font-family: "Acumin Pro Bold", Roboto, sans-serif;
  font-size: 24px;
  color: #311701;
}

.main {
  background: linear-gradient(5.66deg, #A53100 -10.03%, #FF7C1D 37.92%, #FFB057 65.54%, #FFDE89 92.47%);
  color: white;
  font-family: "Acumin Pro", Roboto, sans-serif;
  font-size: 18px;
}

strong {
  font-family: "Acumin Pro Bold", Roboto, sans-serif!important;
  font-weight: bold!important;
  color: #000!important;
  letter-spacing: normal;
}

/* ANIMATION */

.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease;
}
.fade-enter,
.fade-leave-active {
  opacity: 0
}
.slide-left-enter-active,
.slide-left-leave-active,
.slide-right-enter-active,
.slide-right-leave-active {
  transition-duration: 0.5s;
  transition-property: height, opacity, transform;
  transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  overflow: hidden;
}

.slide-left-enter,
.slide-right-leave-active {
  opacity: 0;
  transform: translate(2em, 0);
}

.slide-left-leave-active,
.slide-right-enter {
  opacity: 0;
  transform: translate(-2em, 0);
}

/* FONTS */

@font-face {
  font-family: 'Acumin Pro';
  src: url('./assets/fonts/Acumin-RPro.woff') format('woff');
}

@font-face {
  font-family: 'Acumin Pro Bold';
  src: url('./assets/fonts/Acumin-BdPro.woff') format('woff2');
}

@font-face {
  font-family: 'Acumin Pro Italic';
  src: url('./assets/fonts/Acumin-ItPro.woff') format('woff2');
}
</style>
