import axios from 'axios'
import urlConfig from '@/api/urlConfig'

export default class AuthApi {
  static async validateUser (ldap, store) {
    const config = {
      method: 'put',
      data: { ldap, store },
      url: `${urlConfig.validateUser}`,
      withCredentials: true
    }
    const response = await axios(config)
    return response.data
  }

  static async loginUser (ldap, store) {
    const config = {
      method: 'put',
      data: { ldap, store },
      url: `${urlConfig.loginUser}`,
      withCredentials: true
    }
    const response = await axios(config)
    return response.data
  }
}
