<template>
  <v-btn color="#A53100" width="100" height="100" class="button mb-3" @click="$emit('click')">
    <v-row class="d-flex flex-column justify-center align-center">
      <div>
        <v-img :src="icon" max-height="25%"></v-img>
      </div>
      <p class="text mb-0 mt-2" v-html="text"></p>
    </v-row>
  </v-btn>
</template>

<script>
export default {
  name: 'MapActionButton',
  props: {
    icon: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped>
.text {
  text-transform: none;
  color: #FFDE89;
  text-align: center;
}
.button {
  border-radius: 10px;
}
</style>
