const baseUrl = process.env.VUE_APP_SERVER_BASE_URL

export default {
  validateUser: baseUrl + '/validate-user',
  loginUser: baseUrl + '/login-user',

  updateProgress: baseUrl + '/update-progress',
  submitWin: baseUrl + '/submit-win',

  getStatistic: baseUrl + '/leaderboard',
  getReport: baseUrl + '/report',
  clearLeaderboard: baseUrl + '/clear-leaderboard'
}
