<template>
  <v-col class="d-flex flex-column justify-space-around fill-height">
    <v-container
      class="d-flex justify-center"
      style="width: 70%; max-width: 400px"
    >
      <v-img :src="logo" />
    </v-container>
    <v-container class="d-flex flex-column justify-center align-center">
      <h2 class="white--text mb-7">Instructions</h2>
      <v-card class="dialog d-flex flex-column pa-6 pt-9 pb-9" flat>
        <div class="d-flex align-center mb-7">
          <div class="icon d-flex align-center pl-1">
            <v-img :src="marker" max-width="40" />
          </div>
          <p class="dialog-text ma-0">
            View the Map for hints on item locations.
          </p>
        </div>
        <div class="d-flex align-center mb-7">
          <div class="icon d-flex align-center">
            <v-img :src="scan" width="50" max-width="50" />
          </div>
          <p class="dialog-text ma-0">
            Once you find the Safety Takes Everyone sign, scan the QR code.
          </p>
        </div>
        <div class="d-flex align-center">
          <div class="icon d-flex align-center pl-1">
            <v-img :src="correct" max-width="40" />
          </div>
          <p class="dialog-text ma-0">
            After you have found and scanned all 8 codes, you’ve increased your
            District’s chance to win!
          </p>
        </div>
      </v-card>
    </v-container>
    <v-container class="d-flex flex-column justify-center align-center">
      <SubmitButton text="Get started!" @click="submit" />
    </v-container>
  </v-col>
</template>

<script>
import logo from "../assets/images/main-logo.svg";
import marker from "../assets/images/icons/marker1.svg";
import scan from "../assets/images/icons/scan3.svg";
import correct from "../assets/images/icons/correct.svg";
import SubmitButton from "@/components/ui/buttons/SubmitButton";
import routeNames from "@/router/routeNames";

export default {
  name: "InstructionsPage",
  components: { SubmitButton },
  data() {
    return {
      logo,
      marker,
      scan,
      correct,
    };
  },
  methods: {
    submit() {
      this.$router.push(routeNames.progress);
    },
  },
};
</script>

<style scoped>
.dialog {
  border-radius: 10px;
}

.dialog-text {
  font-size: 16px;
  font-family: "Acumin Pro Bold", Roboto, sans-serif;
}

.icon {
  min-width: 70px;
  width: 70px;
}
</style>
