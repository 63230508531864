<template>
  <v-col class="d-flex flex-column justify-space-around fill-height">
    <PuzzleProgress :progress="progress" />
    <v-container class="d-flex flex-column justify-center align-center mt-1">
      <h2 v-if="progress.length === 0" class="white--text mb-1">
        Time to search!
      </h2>
      <h2 v-else-if="progress.length !== 8" class="white--text mb-1">
        {{ progress.length }} out of 8! Keep it going.
      </h2>
      <div v-else class="d-flex flex-column align-center">
        <h2 class="white--text mb-5">You’re in!</h2>
        <p class="text-center">
          Thank you being a part of our safety culture. By participating, you’ve
          helped your District get closer to winning prizes for all stores.
        </p>
        <p class="text-center">
          Click below to see how your store stacks up against others!
        </p>
      </div>
    </v-container>
    <v-container
      v-if="user.completed"
      class="d-flex flex-column justify-center align-center"
    >
      <IconedButton
        :icon="winner"
        text="Check <br> Leaderboard"
        @click="leaderboardClickHandler"
      />
    </v-container>
    <v-container v-else class="d-flex flex-column justify-center align-center">
      <IconedButton
        :icon="marker"
        text="View Map for Hints"
        @click="mapClickHandler"
      />
      <IconedButton :icon="scan" text="Scan Item" @click="scanClickHandler" />
    </v-container>
  </v-col>
</template>

<script>
import marker from "../assets/images/icons/marker2.svg";
import scan from "../assets/images/icons/scan.svg";
import winner from "@/assets/images/icons/winner.svg";
import IconedButton from "../components/ui/buttons/IconedButton";
import routeNames from "@/router/routeNames";
import PuzzleProgress from "@/components/PuzzleProgress";

export default {
  name: "Progress",
  components: {
    PuzzleProgress,
    IconedButton,
  },
  data() {
    return {
      marker,
      scan,
      winner,
    };
  },
  computed: {
    progress() {
      return this.$store.getters.user.progress;
    },
    user() {
      return this.$store.getters.user;
    },
  },
  methods: {
    mapClickHandler() {
      this.$router.push(routeNames.map);
    },
    scanClickHandler() {
      this.$router.push(routeNames.scan);
    },
    leaderboardClickHandler() {
      this.$router.push(routeNames.leaderboard);
    },
  },
};
</script>

<style scoped></style>
