<template>
  <v-col class="d-flex flex-column justify-space-around fill-height">
    <v-container
      class="d-flex justify-center"
      style="width: 70%; max-width: 400px"
    >
      <v-img :src="logo" />
    </v-container>
    <v-container class="d-flex flex-column justify-center align-center">
      <v-col class="login d-flex flex-column align-center justify-center">
        <h3 class="sm-header mt-5 mb-7">
          Find the 8 items to reveal all<br />
          panels and a chance to win!
        </h3>
        <div
          style="position: relative; width: 100%"
          class="d-flex flex-column justify-center align-center"
        >
          <!-- <input
            v-model="ldap"
            class="input mb-3"
            placeholder="LDAP"
            maxlength="11"
            spellcheck="false"
          />
          <span
            v-if="error.target === 'ldap'"
            class="cross-icon"
            style="top: 13px"
            >✖</span
          >
          <input
            v-model="store"
            type="number"
            class="input"
            placeholder="4-Digit Store Number"
            spellcheck="false"
          />
          <span
            v-if="error.target === 'store'"
            class="cross-icon"
            style="top: 75px"
            >✖</span
          > -->
        </div>
        <p class="error-text mt-2">{{ error.message }}⁣</p>
        <IconedButton
          :icon="binoculars"
          class="mb-7"
          text="Start/Continue<br>Your Quest"
          color="#231F20"
          height="100"
          @click="ssoLogin"
        />
      </v-col>
    </v-container>
    <v-container class="d-flex flex-column justify-center align-center">
      <IconedButton
        :icon="winner"
        text="Check Leaderboard"
        height="90"
        @click="leaderboardHandler"
      />
    </v-container>
  </v-col>
</template>

<script>
import axios from "axios";
import IconedButton from "../components/ui/buttons/IconedButton";
import logo from "../assets/images/main-logo.svg";
import binoculars from "../assets/images/icons/Binoculars.svg";
import smile from "../assets/images/icons/smile.svg";
import winner from "../assets/images/icons/winner.svg";
import AuthApi from "@/api/auth.api";
import routeNames from "@/router/routeNames";

export default {
  name: "Home",
  components: {
    IconedButton,
  },
  data() {
    return {
      logo,
      binoculars,
      smile,
      winner,
      error: {
        target: "",
        message: "",
      },
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    ldap: {
      get() {
        return this.$store.getters.ldap;
      },
      set(value) {
        this.$store.commit("setLdap", value.toUpperCase());
      },
    },
    store: {
      get() {
        return this.$store.getters.store;
      },
      set(value) {
        this.$store.commit("setStore", value);
      },
    },
  },
  methods: {
    async startHandler() {
      // if (!this.ldap) {
      //   this.error.target = "ldap";
      //   this.error.message = "Please enter your LDAP";
      //   return;
      // }
      // if (!this.store) {
      //   this.error.target = "store";
      //   this.error.message = "Please enter your store number";
      //   return;
      // }
      // if (this.store.length !== 4) {
      //   this.error.target = "store";
      //   this.error.message =
      //     "Invalid store number." +
      //     " If your store number is 3 digits, enter 0 as the first digit, e.g., 111 = 0111";
      //   return;
      // }
      const response = await AuthApi.loginUser(this.ldap, this.store);
      if (response.message) {
        console.log(response.message);
        await this.$store.dispatch("setUser", response.data);
        if (this.user.completed) {
          this.error.target = "";
          this.error.message =
            "Your completion has already been recorded. Thank you for playing!";
        } else {
          if (this.$route.query.redirect)
            await this.$router.push(this.$route.query.redirect.toString());
          else await this.$router.push(routeNames.instructions);
        }
      } else {
        this.error.target = "store";
        this.error.message = response.error;
      }
    },
    leaderboardHandler() {
      this.$router.push(routeNames.leaderboard);
    },
    ssoLogin: () => {
      axios
        .post(process.env.VUE_APP_LOGIN_URL, {
          RelayState: process.env.VUE_APP_RELAY_STATE,
        })
        .then((resp) => {
          window.location.href = resp.data.url;
        });
    },
  },
  mounted() {
    console.log(
      "referrer: ",
      document.referrer === process.env.VUE_APP_REFERRER
    );
    if (document.referrer === process.env.VUE_APP_REFERRER) {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const ldap = urlParams.get("ldap");
      const store = urlParams.get("store");
      const redirect = urlParams.get("redirect");

      if (redirect === "/user-not-found") {
        this.error.message = "User not found. Please try again.";
        return false;
      }

      if (ldap && store) {
        this.$store.commit("setLdap", ldap);
        this.$store.commit("setStore", store);
        this.startHandler();
      } else {
        this.error.message = "Invalid login. Please try again.";
      }
    }
  },
};
</script>
<style scoped>
.login {
  background: rgba(165, 49, 0, 0.32);
  border-radius: 10px;
}

.sm-header {
  letter-spacing: 1px;
  font-weight: 600;
}

.input {
  width: 75%;
  height: 50px;
  padding: 0 15px;
  background-color: white;
  color: #847f7c;
  font-size: 16px;
  font-family: "Acumin Pro", sans-serif;
  font-weight: 600;
  letter-spacing: 1px;
}

.input::placeholder {
  color: #847f7c;
}

.error-text {
  width: 80%;
  text-align: center;
  font-size: 14px;
}

.cross-icon {
  position: absolute;
  right: calc(17%);
  color: #df1003;
}
</style>
