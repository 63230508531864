export default {
  en: [
    "This is where hazardous spill waste is stored.",
    "This is where you store and retrieve personal items.",
    "This is near the office of the person that runs the store.",
    "The first thing you do before you head out on the sales floor.",
    "This area in D30 is used to help customers with orders for doors and windows.",
    "Where recall info is posted for customers to see.",
    "This area in D29 is used to help customers with orders for large appliances.",
    "Contractors and PRO's go to this area for help and to check out.",
  ],
  fr: [
    "Les clients achètent des articles de l’allée D28 pour leurs besoins en matière de nettoyage et d'assainissement!",
    "Cette zone de l’allée D24 sert à aider les clients qui souhaitent obten ir des conseils pour mélanger la peinture.",
    "Les clients utilisent ces articles dans l’allée D22 pour les travaux de construction.",
    "Vous devez toujours utiliser un équipement de levage motorisé pour déplacer des marchandises lourdes dans l’allée D23.",
    "Vous pouvez trouver toutes les lames en D25 ici.",
    "Les clients finissent leurs achats près de cette zone.",
  ],
};
